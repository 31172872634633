<template>
    <section class="mt-10"><LineChart :chartData="ChartData" :chartOptions="chartOptions" :styles="chartStyles" /></section>
    <section class="mt-10">
        <table class="min-w-full divide-y divide-fibonaki-border">
            <thead>
                <tr>
                    <th scope="col" class="px-4 py-3 text-left text-sm font-medium text-gray-900">{{ $tc('date', 1) }}</th>
                    <th scope="col" class="px-4 py-3 text-left text-sm font-medium text-gray-900">{{ $tc('value', 1) }}</th>
                    <th scope="col" class="px-4 py-3 text-left text-sm font-medium text-gray-900">{{ $tc('evolution', 1) }}</th>
                </tr>
            </thead>
            <tbody v-if="lastWeekEmissions">
                <tr v-for="emission in lastWeekEmissions.slice().reverse()" :key="emission">
                    <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500">{{ emission.date }}</td>
                    <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500">{{ $filters.getDecimalValue(emission.value, 2) }} {{ emission.unit }}</td>
                    <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500">
                        <Badge :color="statusColor(emission.difference)">{{ $filters.getDecimalValue(emission.difference, 2) }} {{ emission.unit }}</Badge>
                    </td>
                </tr>
            </tbody>
        </table>
    </section>
</template>

<script>
    import LineChart from '@/components/chart/LineChart';
    import Badge from '@/components/general/Badge.vue';

    export default {
        components: {
            LineChart,
            Badge,
        },
        created() {
            this.$store.dispatch('energy_data/fetchEmissionData');
        },
        data() {
            return {
                chartStyles: {
                    height: '400px',
                },
                chartOptions: {
                    animation: { duration: 0 },
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: {
                        display: true,
                    },
                    tooltips: {
                        titleFontSize: 24,
                        bodyFontSize: 20,
                    },
                    scales: {
                        xAxes: [{ gridLines: { display: false }, ticks: { fontSize: 8 } }],
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: false,
                                    callback: function (value) {
                                        return '€' + ' ' + value;
                                    },
                                },
                            },
                        ],
                    },
                },
            };
        },
        computed: {
            ChartData() {
                return {
                    labels: this.$store.getters['energy_data/emission_data'].emission_labels,
                    datasets: [
                        {
                            label: 'CO2',
                            fill: true,
                            backgroundColor: 'hsla(204, 68%, 58%, 0.1)',
                            pointRadius: 0,
                            borderWidth: 1,
                            borderColor: '#4ca3dd',
                            data: this.$store.getters['energy_data/emission_data'].emission_values,
                        },
                    ],
                };
            },
            lastWeekEmissions() {
                return this.$store.getters['energy_data/week_emission_data'];
            },
        },
        methods: {
            statusColor(difference) {
                if (difference == 0) {
                    return 'gray';
                } else if (difference < 0) {
                    return 'green';
                } else {
                    return 'red';
                }
            },
        },
    };
</script>
